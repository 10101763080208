
.tenure-btn {
    border: solid; 
    border-radius: 10px;
    border-width: 1px;
    border-color: #B9B9B9;
    // padding-inline: 15px;
    padding: 11px;
    cursor: pointer;
    font-family: Montserrat;
    color: #B9B9B9;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.tenure-btn:hover {
    border-color: #F78D2D;
    // background-image: linear-gradient(#F9A961, #FED3AC);
    color: black;
}

.tenure-selected {
    background-image: linear-gradient(#F9A961, #FED3AC);
    color: white;
    border-color: white;

}
