.partner-button-footer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.8rem;
  cursor: pointer;
}

.next-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.25rem;
}

.prev-button {
  display: flex;
  padding: 0.4375rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid #EE3E80;
  width: 4.5rem;
}

.add-button {
  display: flex;
  padding: 0.375rem 1rem;
  align-items: flex-start;
  gap: 0.625rem;
  width: 6.125rem;
  border-radius: 0.25rem;
  border: 1px solid #EE3E80;
  color: #EE3E80;
  cursor: pointer;
}

.partner-action-button {
  display: flex;
  padding: 0.6875rem 0.5625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  height: 2.625rem;
}

.partner-action-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Status-Inactive, #EE3E80);
  color: #FFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.01rem;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 100%;
  gap: 5px;
  // margin-left: 0.5rem;
}

.partner-action-button-inactive {
  background: var(--Status-Inactive, #E9ECEF);
  color: var(--Text-Secondary, #B0B0B0);
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0.25rem;
  letter-spacing: 0.01rem;
}

.secondery-partner-button {
  display: flex;
  padding: 0.375rem 1rem;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  width: fit-content;
  border-radius: 0.25rem;
  border: 1px solid #EE3E80;
  color: #EE3E80;
  cursor: pointer;
}

.qc-action-button {
  display: flex;
  width: 6rem;
  height: 2.5rem;
  padding: 0.625rem 0.875rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.01rem;
  cursor: pointer;

  .approve {
    color: #11B75A;
  }

  .reject {
    color: #DC3545;
  }

  .rekyc {
    color: #F1B300;
  }

  .on_hold {
    color: #F1B300;
  }
}