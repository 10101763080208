.file-picker-button{
  border-radius: 0.25rem;
  border: 1px solid #B0B0B0;
  background: var(--Status-Active, #FFF);
  color: #EE3E80;
  padding: 0.44rem 1.5rem;
  background-color: #FFF;
  text-align: center;
  cursor: pointer;
}

.picked-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FDECF2;
  border-radius: 1.5rem;
  padding: 0.3rem 0.6rem;
}

.supportive-text {
  color: #DC3545;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00625rem;
}

.partner-heading {
  color: var(--Label-Primary, #707070);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.00875rem;

  span {
    color: red;
  }
}