.loan-details-page{
    display: flex;
    flex-direction: column;
    // width: 22.5rem;
    // height: 26.7rem;
    width: 100vw;
    height: calc(100% - 9rem);
    border-radius: 1rem 1rem 0rem 0rem;
    background: #FFF;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 10.5rem;
    animation: moveBottomPopUpLoanDetails 2s forwards;
    padding: 2.125rem 1rem 11.6875rem 1rem;
  
    .loan-amount{
      display: inline-flex;
      padding: 0.3125rem 2.25rem 0.25rem 2.25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.1875rem;
      border-radius: 0.75rem;
      border: 1px solid #FEF0E4;
      background: #FFF;
      width: auto;
      position: absolute;
      top: -1.5rem;
      left: 25%;
  
      .amount{
        color: #F78D2D;
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 0.01rem;
      }
  
      .text{
        color: var(--text-primary, #3E3E3E);
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.0075rem;
      }
      .text1{
        color: var(--text-primary, #3E3E3E);
        font-size: 0.85rem;
        // font-weight: 600;  
        letter-spacing: 0.0075rem;
      }
    }
  
    .kfs-details{
      display: flex;
      width: 20.5rem;
      padding: 1rem 0.5rem;
      flex-direction: column;
      justify-content: center;
      // align-items: flex-start;
      gap: 0.625rem;
      border-radius: 0.75rem;
      border: 1px solid #FEF0E4;
      background: #FFF;
    }

    .repayment{
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 10px;
    }

    .t-c{
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        margin-top: 10rem;
    }

    .t-c-b{
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;
      margin-top: 2rem;
  }


    .button-footer-2{
        position: absolute;
        left: 50%;
        margin-top: 5rem;
        transform: translateX(-50%);

    }

    .button-font-size{
        margin-top: 12px;
        color: #EE3E80;
        font-size: 0.9rem;
    }

    .button-footer-4{
        height: 3rem;
        width: 22rem;
        border-radius: 15px;
        background: transparent;
        border: 1px solid #FEF0E4;
    }


    .heading-1{
        font-size: 0.8rem;
        font-weight: 500;
        // margin-bottom: 2%;
    }

    .heading2{
        font-size: 0.75rem;
        font-weight: 400;
        margin-right: 35%;
    }


    .kfs-amount{
        font-size: 0.85rem;
        font-weight: 400;
    }

    .check-box{
        accent-color: #ee3e80;
        border-radius: 16px;
        border-color: #ee3e80;        
        height: 18px;
        width:  18px;
    }

    .text-down{
        color: #45C1C0;
        font-size: 0.75rem;
    }

    .break-line{
        width: 19.5rem;
        height: 0.0375rem;
        background: #FEF0E4;
    }
  
    .credited-amount{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0.3rem;
      padding: 0.6rem;
  
      .credit-text{
        color: var(--text-primary, #3E3E3E);
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.0075rem;
      }
  
      .credit-value{
        color: var(--text-primary, #3E3E3E);
        text-align: right;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.00875rem;
      }
    }
  
    .credit-card-report{
      width: 20.5rem;
      height: 3rem;
      flex-shrink: 0;
      border-radius: 0.625rem;
      border: 1px solid #E9ECEF;
      background: #FFF;
      padding: 0.6rem;
      margin-top: 1.38rem;
      display: flex;
      justify-content: space-between;
  
      .text-1{
        .text-2{
          color: var(--text-primary, #3E3E3E);
          font-size: 0.75rem;
          font-weight: 400;
          letter-spacing: 0.0075rem;
        }
  
        .msg{
          color: var(--text-secondary, #9F9D9D);
          font-size: 0.625rem;
          font-weight: 400;
          letter-spacing: 0.00625rem;
        }
      }
    }
  
    .terms-and-condition{
      color: var(--text-secondary, #9F9D9D);
      font-size: 0.625rem;
      font-weight: 400;
      letter-spacing: 0.00625rem;
      margin-top: 1.88rem;
  
      span{
        color: #EE3E80;
      }
    }
  }
  
  @keyframes moveBottomPopUpLoanDetails {
    from{
        top: 15rem;
    }
    to{
        top: 9rem
    }
  }
  
  @media (min-width: 500px) {
    .loan-details-page{
      width: 22.5rem;
    }
  
    .tncAgreement{
      width: 19rem;
    }
  }
  
  @media (max-width: 500px) {
    .kfs-details{
      width: 100% !important;
    }
  }
  
  .question-svg{
    margin-left: 0.2rem;
  }
  
  /* For Webkit browsers (Chrome/Safari) */
  .loan-details-page::-webkit-scrollbar {
    display: none;
  }
  
  .kfs-details::-webkit-scrollbar{
    display: none;
  }
  
  /* For Firefox */
  .loan-details-page, .kfs-details, .tncAgreement {
    scrollbar-width: none;
  }
  
  /* For IE and Edge */
  .loan-details-page, .kfs-details, .tncAgreement {
    -ms-overflow-style: none;
  }
  
  .tncAgreement::-webkit-scrollbar {
    display: none;
  }
  
  .tncAgreement::-webkit-scrollbar{
    display: none;
  }
  
  .loan-details-button1 {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .know-more {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end;
  
    .know-more-tag{
      color: #EE3E80 !important;
      font-size: 0.625rem;
      font-weight: 400;
      letter-spacing: 0.00625rem;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  
  .know-more-content{
    color: var(--text-primary, #3E3E3E);
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.9375rem; /* 150% */
    letter-spacing: 0.00625rem;
  }
  
  #my-tooltip{
    display: inline-flex !important;
    padding: 0.5rem 2.25rem 0.5rem 0.375rem;
    align-items: center;
    border-radius: 0.5rem;
    border: 0.4px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 2;
    position: absolute;
    
  }
  
  .tncAgreement{
    position: absolute;
    height: 500px;
    z-index:10;
    padding: 20px;
    background: #FFFF;
    border-radius: 20px;
    overflow-y: scroll;
    overflow-x: scroll;
    margin: 2rem;
  }