.roseLoader{
    background:white;
    ;
  }
  .rose-colorLoader{
    color: white;
  }
  
  .centerLoader {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 30vh;
  }
  
  .circleLoader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    background-color: white;
    font-weight: 700;
    border-radius: 50%;
    border: 1px solid #E9ECEF;
    // width: 1.25rem;
    // height: 1.25rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms ease;
  }
  
  .pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  
  
  .masterLoader{
    color:black;
    float:right;
  }
  
  @media (max-width:900px){
    .masterLoader{
      display:none
    }
  }
  