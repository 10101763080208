.switch-buttons {
  border: solid;
  border-width: 0.5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #707070;
  background-color: #f1f1f1;
  border-color: #b9b9b9;
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 10px;
}

.switch-buttons:hover {
  cursor: pointer;
}

.switch-selected {
  background-color: white;
  color: black;
  border-bottom: none;
}

.switch-btn-container {
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  
}

.click-prop {
  cursor: pointer;
}

.cashflow-price-box {
  display: grid !important;
  justify-items: center;
}

.cashflow-pdng-first {
  padding-block: 20px;
  padding-inline: 20px;
}

.cashflow-pdng-second {
    padding-block: 10px;
    padding-inline: 20px;
  }
