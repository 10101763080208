.hunting-data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .key {
    color: var(--Label-Primary, #707070);
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.00875rem;
  }
}

.hunting-qc-button {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.video-container {
  width: 400px;
  height: 250px;
}

.sticky-style {
  position: fixed;
  top: 0;
  background-color: #F9FAFB;
  z-index: 1;
  width: 100%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

img {
  width: 100%;
  height: 250px;
}