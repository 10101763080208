.profile-picture-container {
  border: solid;
  display: flex;
  border-width: 1px;
  border-color: white;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.label-style {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  text-wrap: nowrap;
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  height: auto;
  width: auto;
  padding: 5px;
  position: absolute;
  margin-left: 150px;
}

.red-label-style {
  background-color: #fbebec;
  color: #dc3545;
}

.yellow-label-style {
  background-color: #FEF7E6;
  color: #F1B300;
}
.green-label-style {
  background-color: #E7F8EE;
  color: #10B759;
}
