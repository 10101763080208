$bgcolor: #f5f6fa;

.chartHolder {
  display: flex;
  gap: 20px;
  // padding: 20px;
  // padding-bottom: 30px;
  
}

.chartTile {

  background: #fff;
  border-radius: 10px;
  padding: 15px;
  border: solid;
  border-width: 1px;
  border-color: #B9B9B9;

}

.centre {
    justify-content: center;
}

.chartContainer {
    
    background: #fff;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 14px;
    margin-bottom: 30px !important;
    margin-top: 20px !important;
    padding: 20px;

}

.singleChartContainer {
    background: #fff;
    border-radius: 14px;
    padding: 10px
}

.contentContainer {
  margin-top: 80px;
  padding: 40px;
}

.mainContainer {
  background-color: $bgcolor;
}


.textHeader {
  // margin-left: 15px;
  // padding-top: 10px;
  margin-bottom: 5px;
  color: #707070;
  font-weight: 600;
  font-size: 14px;
  font-family: Montserrat;
  margin-bottom: 20px;
}

.chartSubHeader {
  font-weight: 500;
  font-family: Montserrat;
  font-size: 12px;
  color: #707070;
}


.loaderStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
}