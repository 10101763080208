.red-border{
  border: 1px solid red !important;
}

.green-border {
  border: 1px solid green;
}

.multiselect-style {
  width: 100%;
  div{
    height: 36px;
    div{
      height: 35px;
    }
  }
}

.partner-dropdown {
  select:focus {
    box-shadow: none !important;
    border: 1px solid #F69EBF;
  }


}

.partner-heading {
  color: var(--Label-Primary, #707070);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.00875rem;

  span {
    color: red;
  }
}


.clear-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
}

.clear-icon {
  width: 12px;
  height: 12px;
  fill: #999;   
}


.dropdown-options {
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: auto;
}

.dropdown-options div {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
}

.dropdown-options .selected {
  background-color: #f0f0f0;
}

.remove-option-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.remove-icon {
  width: 16px;
  height: 16px;
}

.clear-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

