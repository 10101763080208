.hlp-type {
  display: flex;
  justify-content: center;
}

.hlp-type-title {
  color: var(--Text-Primary, #3E3E3E);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  width: 20.875rem;
  /* H2/18PX/500 */
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.01125rem;
  color: #EE3E80;
}

.select-hlp {
  width: 210px;
  border: none;

  select {
    border: none;
    border-radius: 5px;
    background-color: #F1F2F8;
    color: #717ABC;
  }
}