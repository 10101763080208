.application-progress{
    border: 1px solid #FEF0E4;
    background-color: #fff;

    .sub-heading{
        color: #9F9D9D;
        font-size: 0.75rem;
    }

    .text{
        font-size: 0.875rem;
        color : #9F9D9D;
    }
}


.text-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    p {
        margin-left: 0.6rem;
    }
} 

.circle {
    background-color: white;
    font-weight: 700;
    border-radius: 50%;
    border: 1px solid #E9ECEF;
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms ease;
}

.circle-small {
    background-color: white;
    font-weight: 700;
    border-radius: 50%;
    border: 1px solid #E9ECEF;
    width: 1rem;
    height: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms ease;
    margin-left: 2px;
}

.text-wrap.active div.circle {
    border-color: var(--line-border-fill);
    background-color: #45C1C0;
}

.text-wrap.active div.circle-small {
    border-color: var(--line-border-fill);
    background-color: #45C1C0;
}

.completed-step {
    color: var(--text-secondary, #9F9D9D);
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;
}

.incomplete-step {
    color: var(--text-primary, #3E3E3E);
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;
}

.status-heading{
    font-size: 0.75rem;
    color: #3E3E3E;
    text-align: center;
}

.containerpwa {
    display: flex;
    align-items: center; 
  }
  
.left1 {
    margin-right: auto; 
}
  
.center1 {
    margin: 0 auto; 
}
  