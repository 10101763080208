$tableheadcolor: #B0B0B0;
$bgcolor: #f5f6fa;

.thead {
    background-color: $tableheadcolor;
}

.has-search .form-control {
    padding-left: 2.375rem;
    width: 328px;
    border-radius: 10px;
  }
  
  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
  
  .customers-main-container {
    background-color: $bgcolor;
    padding: 40px;
    margin-top: 80px;
  }

  .filters {
    display: flex;
    align-items: center;
  }

  .pagination {
    display: flex;
}

.btn-pointer-class:hover {
  cursor: pointer;
}



.back-button{ 
  height: 30px;
  width: 30px;
  color: #707070;
  border: solid;
  border-color: #D5D5D5;
  border-width: 1px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 1000;
  cursor: pointer;
}

.back-button:hover {
  color: black;
  background-color: #f5f6fa;
}