.button{
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #EE3E80 0%, #FF75A9 100%);
  box-shadow: 0px 4px 8px 0px rgba(238, 62, 128, 0.25);
  margin: 2px;
  cursor: pointer;
  width: 20.5rem;
  color: #FFF;
}
.buttonbank{
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #EE3E80 0%, #FF75A9 100%);
  box-shadow: 0px 4px 8px 0px rgba(238, 62, 128, 0.25);
  margin: 2px;
  cursor: pointer;
  width: 20.5rem;
  color: #FFF;
}
.active-color{
  background: linear-gradient(180deg, #EE3E80 0%, #FF75A9 100%);
}

.inactive-color{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), linear-gradient(180deg, #EE3E80 0%, #FF75A9 100%);
}
.active-color-bank{
  background: linear-gradient(180deg, #EE3E80 0%, #FF75A9 100%);
}

.inactive-color-bank{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), linear-gradient(180deg, #EE3E80 0%, #FF75A9 100%);
}

.secondery-button{
  display: flex;
  width: 20.5rem;
  padding: 0.625rem 8.3125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid #EE3E80;
  background: #FFF;
}