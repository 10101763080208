.tablebodytitle{
   //styleName: Label/L1 Medium;
font-family: "Montserrat";
font-size: 14px;
font-weight: 500;
line-height: 17.07px;
text-align: left;
color: #242424;

}
.roots {
    .MuiTableCell-head  {
        color: "white";
        background-Color: "blue"
    }
}
.table-body-title {
    color: #5A607F;
    font-weight: 400;
    font-size: 12px;

}
.table-body-name {
    color: #242424;
    font-weight: 500;
    font-size: 14px;
}
.table-count-results {
    color:  #768EA7;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
}
.table-gap-records {
  gap: 30%;
  display: flex;
}
.link-pointer {
    color: #EE3E80;
    cursor: pointer;
}
.apply-button-caf {
  background-color: #EE3E80;
    color: #fff;
    padding: 2px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}
.apply-button {
    background-color: #EE3E80;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  .apply-button-upload {
    background-color: #EE3E80;
    color: #fff;
    padding: 10px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
  }

  .button-row{
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  .button-row-task{
    padding: 10px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
  }
.tableRow{
    font-size: 14px;
    font-weight: 500;
    color: #5A607F;
}

.task-assignment-layout{
    display: flex;
  
    .left-scroller {
    //   flex: 1;
      width: 90%;
    //   height: calc(100vh - 8rem);
    //   overflow: scroll;
    }
  
    .left-scroller::-webkit-scrollbar {
      display: none;
    }
  
    .right-scroller {
      width: 10%;
    //   height: calc(100vh - 8rem);
    //   overflow: scroll;
    }
  
    .right-scroller::-webkit-scrollbar {
      display: none;
    }
  }

.tableRowcol {
    width: 1111px;
height: 40px;
gap: 0px;
margin: 1rem 0;
    color: inherit;
    border: 0;
// opacity: 0px;

}
.tablestatusbackground {
    width: Fixed (74px)px;
    height: Hug (19px)px;
    top: 3px;
    padding: 2px 8px 2px 8px;
    gap: 10px;
    // opacity: 0px;
    background:  #F1F5FA;    
}
.tablestatusbackgroundheading {
    color:  #5A607F;
    //styleName: Subtitle/S1 Regular;
font-family: Montserrat;
font-size: 12px;
font-weight: 400;
line-height: 14.63px;
text-align: center;
width: 44px;
height: 15px;
gap: 0px;
// opacity: 0px;


}   
.productbuttonbusinessloan{
Width: Hug (103px);
height: Hug (19px);
padding: 2px, 8px, 2px, 8px;
gap: "10px";
background: "#FDE7EF";

}
.productbuttonbusinessloan{
    Width: Hug (103px);
    height: Hug (19px);
    padding: 2px, 8px, 2px, 8px;
    gap: "10px";
    background: "#FDE7EF";
    
}
.productbuttonNonCredit{
    Width: Hug (83px);
    height: Hug (19px);
    padding: 2px, 8px, 2px, 8px;
    gap: "10px";
    background: #E3D9F0;
    
}

    @media (max-width: 550px) {
        .table-gap-records {
            gap: 0%;
            display: flex;
          }
}
.productbuttonPaymint{
    Font :"Montserrat";
    width: 51px;
height: 15px;
gap: 0px;
// opacity: 0px;
    weight :400;
    size: "12px";
    height:"14.63px";
    align: "Center";
    background: #10BDB8;
    
    
}