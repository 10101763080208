.logIn-background {
  background: linear-gradient(
    135.28deg,
    rgba(249, 169, 97, 0.7) 2.4%,
    rgba(254, 211, 172, 0.7) 97.92%
  );
  position: absolute;
  height: 100%;
  width: 100%;

  .ntl-img {
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0;
  }

  .topRigtOval {
    position: absolute;
    top: 0;
    right: 0;
  }

  .topLeftOval {
    position: absolute;
    top: 0;
    left: 0;
  }

  .bottomLeftOval {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .bottomRightOval {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .arth-login-logo {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .login-image {
    height: 20rem;
    width: 30rem;
    border-radius: 18px;
    background: white;
    opacity: 30%;
    background-blend-mode: overlay;
  }

  .login-text {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-family: Montserrat;
    font-size: 18px;
    line-height: 21.94px;
    letter-spacing: 0.01em;
  }

  .login-email-text {
    position: absolute;
    top: 30%;
    left: 20%;
    transform: translate(-50%, -50%);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: 0.01em;
    text-align: center;
    display: flex;
  }

  .login-email-input {
    position: absolute;
    top: 40%;
    left: 48%;
    transform: translate(-50%, -50%);
    width: 328px;
    height: 38px;
    border-radius: 14px;
    border: 0.6px 0px 0px 0px;
    padding-left: 11px;
  }

  .login-password-text {
    position: absolute;
    top: 55%;
    left: 20%;
    transform: translate(-50%, -50%);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .login-password-input {
    position: absolute;
    top: 65%;
    left: 48%;
    transform: translate(-50%, -50%);
    width: 328px;
    height: 38px;
    border-radius: 14px;
    border: 0.6px 0px 0px 0px;
    padding-left: 11px;
  }

  .login-button-footer {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99px;
    height: 30px;
    border-radius: 10px;
    border: transparent;
    background: linear-gradient(180deg, #ee3e80 0%, #ff75a9 100%);

    .login-button-footer-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      letter-spacing: 0.01em;
    }
  }
}

.invalid-creds {
  color: red;
  font-size: 14px;
  font-family: Montserrat;
  position: absolute;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
