.navbar-container {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 1000;

  .navbar-arth-logo {
    position: absolute;
    top: 20px;
    left: 40px;
  }

  .navbar-dashboard-text {
    position: absolute;
    top: 40px;
    left: 150px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: 0.01em;
    cursor: pointer;
  }

  .navbar-customer-text {
    position: absolute;
    top: 40px;
    left: 300px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: 0.01em;
    cursor: pointer;
  }
  
  .inactive-tab {
    color: #707070 !important
  }
  .navbar-underline-d {
    position: absolute;
    bottom: 0;
    left: 150px;
    width: 100px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 5px;
    background-image: linear-gradient(#ee3e80, #ff75a9);
  }

  .navbar-underline-c {
    position: absolute;
    bottom: 0;
    left: 298px;
    width: 100px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 5px;
    background-image: linear-gradient(#ee3e80, #ff75a9);
  }

  .navbar-circle {
    // position: absolute;
    margin-left: auto;
    margin-top: 15px;
    margin-right: 20px;
    top: 20px;
    right: 40px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: linear-gradient(180deg, #ee3e80 0%, #ff75a9 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    .navbar-circle-text {
      color: white;
    }
  }
}
