.image-slider {
    position: relative;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .image {
    border-radius: 12px;
 }
 .left-slider-btn {
   //  position: absolute;
   //  left: 3rem;
    top: 50%;
    font-size: 1rem;
    color: #707070;
    z-index: 5;
    cursor: pointer;
    user-select: none;
    margin-right: 100%;
 }
 .right-slider-btn {
   //  position: absolute;
   //  right: 3rem;
   margin-left: 100%;
   top: 50%;
   font-size: 1rem;
   color: #707070;
   z-index: 5;
   cursor: pointer;
   user-select: none;
 }
 .currentSlide {
    transition-duration: 0.5s ease;
    opacity: 0;
 }
 .currentSlide.active {
    opacity: 1;
    transition-duration: 0.5s;
    transform: scale(1.2);
 }
 h2 {text-align: center;} 