.listing-wrapper {

    cursor: pointer;
  
    .left-side-card {
  
      margin-left: 0.8rem;
  
      .partner-names {
        size: 1rem;
        line-height: 1rem;
        color: #3E3E3E;
        text-align: start;
      }
  
      .partner-type {
        width: 4.8rem;
        height: 2rem;
        background-color: #F1F2F8;
        border-radius: 4px;
        color: #717ABC;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    .right-side-card {
      margin-right: 0.8rem;
  
      .profile-info {
        font-weight: 500;
        size: 0.8rem;
        line-height: 1rem;
        color: #707070;
        display: flex;
        justify-content: flex-end;
      }
  
      .profile-date {
        font-weight: 400;
        size: 0.8rem;
        line-height: 1rem;
        color: #B0B0B0;
      }
    }
  }
  .table-body-title {
    color: #5A607F;
    font-weight: 400;
    font-size: 12px;

}
.table-body-name {
    color: #242424;
    font-weight: 500;
    font-size: 14px;
}
  .under-review {
    color: #ED6195;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;
  }
  @media (max-width: 550px) {
    .labelHeader {
    font-size:small;
      // height: 300px;
    }
  }
  