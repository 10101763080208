.new-row {
    display: flex;
    gap: 16px;
    margin-top: 10px;
    margin-right: 10px;
    // justify-content: center;
}

.pill {
   border: solid;
   border-width: 1px;
   height: auto;
   width: auto;
   padding-inline: 28px;
    padding-block: 6px;
   border-radius: 10px;
   border-color: #B9B9B9;
   font-family: Montserrat;
   cursor: pointer;
   display: block ruby;
   }

.pill:hover {
    border-color: #EE3E80;
}

   

.pill-select {
    background-image: linear-gradient(#EE3E80,#FF75A9);
    color: white
}

.center-container {
    display: flex;
    justify-content: center;
}

.apply-button {
    border-radius: 10px;
    background-image: linear-gradient(#EE3E80,#FF75A9);
    color: white;
    padding-inline: 28px;
    padding-block: 6px;
    cursor: pointer;
    margin-top: 20px;
}

.popover-head {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    color: #707070;
    margin-bottom: 20px;
}


.popover-foot {
    margin-top: 30px;
    color: #707070;

}

.popover{
    max-width: none !important;
}

