.container1 {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  .row {
    .form-row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      //.col-md-4 {
      //  flex: 1;
      //  margin-right: 1rem;
      //
      //  &:last-child {
      //    margin-right: 0;
      //  }
      //}
    }

    //.col-md-4 {
    //  &:first-child {
    //    margin-right: 1rem;
    //  }
    //
    //  //&:nth-child(2) {
    //  //  margin-right: 1rem;
    //  //}
    //
    //  &:last-child {
    //    margin-right: 0;
    //  }
    //}
  }

  .font-weight-bold {
    font-weight: bold;
    margin-bottom: 1rem;
  }
}

.input {
  width: 111px;
  border: 1px;
  border-radius: 4px;
  height: 30px;
  border-color: black;
}

.p-44 {
  padding: 1.5rem;
  background-color: #f5f5f5;

  .breadcrumb {
    margin-bottom: 1rem;
  }

  .box-input {
    display: flex;
    align-items: center;
    height: 42px;

    .input-box {
      width: 33.33%;
      border: 1px solid #ccc;
      border-right: none;
      padding: 0 0.5rem;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0;
        font-size: 1rem;
        text-align: center;
      }
    }

    .pre-input-text {
      width: 100px;
      padding: 0 0.5rem;
      border: 1px solid #ccc;
      border-left: none;
      border-right: none;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-input {
    height: 42px;
    border: 1px solid #ccc;
    padding: 0 0.5rem;
    border-radius: 4px;

    &:focus {
      outline: none;
      box-shadow: 0 0 2px 1px rgba(0, 123, 255, 0.25);
    }
  }

  .description-table {
    border-collapse: collapse;
    background: #eeecec;
    width: 100%;
  }

  .description-table th,
  .description-table td {
    // border: 1px solid #ddd;
    padding: 8px;
  }

  .description-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .description-table thead {
    border: 1px solid #ddd;
  }

  .description-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ecf1fd;
    color: #000000;
    border: 0;
  }

  .description-table button {
    background-color: #f44336;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .generate-button {
    align-items: center;
    width: 124px;
  }

  .custom-action-button {
    background-color: #EE3E80;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .generate-button {
    //display: inline-flex;
    //justify-content: center;
    margin: 20px 0 20px 0;
    //margin-left: 540px;
    //margin-bottom: 10px;
    //width: 124px;
    //height: 40px;
    // float:inline-start;
  }

}

.inside-container {}


.addtax-style {
 
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: -0.20000000298023224px;
text-align: left;
color: #292D32;

}
.addtex-button-modal {
  width: 148px;
height: 44px;
padding: 12px 24px 12px 24px;
gap: 8px;
border-radius: 10px;
border: 1px ;


}
.addtax-style-title {
  
font-size: 18px;
font-weight: 600;
line-height: 28px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.addtax-selectinput {
  width: 302px;
height: 40px;
top: 46px;
gap: 327px;
border-radius: 9px;
border: 1.17px;


}

// .popup {
//   padding: 10px !important;
//   width: 520px !important;
//   height: auto !important;
//   position: fixed !important;
//   transform: translate(-50%, -50%) !important;
//   top: 50% !important;
//   left: 50% !important;

// }




.popup {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 350px;
  border-radius:16px;
  z-index: 1000;
}

.popup > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup > div:first-child span {
  font-size: 18px;
  font-weight: bold;
}

.popup > div:first-child button {
  // background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.popup > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popup > div:nth-child(2) span {
  font-size: 16px;
  font-weight: bold;
}

.popup > div:nth-child(2) label {
  display: block;
  margin-bottom: 5px;
}

.popup > div:nth-child(2) input[type="radio"] {
  margin-right: 5px;
}

.popup > div:nth-child(3) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.popup > div:nth-child(3) button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup > div:nth-child(3) button:first-child {
  background-color: #ccc;
  color: #333;
}

.popup > div:nth-child(3) button:last-child {
  background-color: #EE3E80;
  color: #fff;
}
.custom-label-invoice {
  color: var(--Label-Primary, #707070);  /* Fallback to #707070 if the variable is not defined */
  font-size: 0.875rem;
  font-weight: 500;
}