.button-footer{
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
.button-footer-bank{
  position: absolute;
  // bottom: 2rem;
  margin-top: 1.8rem;
  left: 50%;
  transform: translateX(-50%);
}


