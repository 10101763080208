.filter-buttons {
  display: flex;
  // width: fit-content;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: auto;
  border-radius: 10px;
  align-items: center;
  border: 0.05px solid #d5d5d5;
  background-color: white;
}

.outer-button {
  padding: 10px;
  color: #707070;
}

.button-container:hover {
  background: #d5d5d5;
}

.divider {
  border-left: 1px solid black;
  height: 40px;
  border-color: #d5d5d5;
}

.inner-button {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 5px;
  color: #b0b0b0;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.inner-button:hover {
  background: #d5d5d5;
  color: black;
}

.row-border {
  border-left: solid;
  border-left-color: #d5d5d5;
  border-width: thin;
  background-color: white;
}

.col-border {
  border-color: #d5d5d5;
}

.buttons-container {
  background-color: white;
  padding-top: 30px;
  align-content: center;
}

.date-picker {
  display: none;
}

.popover-body {
  max-width: none !important;
}

.arrow-spc {
  margin-left: 3px;
}

.outer-btn-scnd {
  display: flex;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  height: 40px;
  align-items: center;
  cursor: pointer;
}

.outer-btn-scnd:hover {
  background: #d5d5d5;
  color: black;
}

.react-datepicker__close-icon {
  display: none !important;
}

// .react-datepicker-popper
// {
//   transform: none !important;
// }

