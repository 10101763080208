.loan-page {
  display: flex;
  flex-direction: column;
  // width: 22.5rem;
  // height: 26.7rem;
  width: 100vw;
  height: calc(100% - 9rem);
  border-radius: 1rem 1rem 0rem 0rem;
  background: #FFF;
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 10.5rem;
  animation: moveBottomPopUpLoanDetails 2s forwards;
  padding: 2.125rem 1rem 11.6875rem 1rem;

  .loan_amount {
    display: inline-flex;
    padding: 0.3125rem 2.25rem 0.25rem 2.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.1875rem;
    border-radius: 0.75rem;
    border: 1px solid #FEF0E4;
    background: #FFF;
    width: auto;
    position: absolute;
    top: -1.5rem;
    left: 25%;
    transition: color 0.3s ease; 

    .amount-1 {
      color: #F78D2D;
      font-size: 1.4rem;
      font-weight: 600;
      letter-spacing: 0.01rem;

    }

    .text-0 {
      color: var(--text-primary, #3E3E3E);
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.0075rem;
    }

    .text1 {
      color: var(--text-primary, #3E3E3E);
      font-size: 0.85rem;
      font-weight: 600;
      letter-spacing: 0.0075rem;
    }
    .animate-color {
      background: blue;
    }
  }

  .kfs-details-2 {
    display: flex;
    padding: 1rem 0.5rem;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    // align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.75rem;
    border: 1px solid #FEF0E4;
    background: #FFF;
  }

  .repayment {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
  }

  .tc1 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 1%;
  }

  .tc2 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 3%;
  }

  .tc3 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 1%;
  }

  .tc4 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 1%;
  }

  .tc-4 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 3%;

  }

  .tc5 {
    display: grid ;
    grid-template-columns: auto 1fr;
    margin-top: 10%;
  }


  .bpbamount {
    font-size: 0.95rem;
    font-weight: 500;
    margin-left: 13%;
  }

  .emiamount {
    font-size: 0.95rem;
    font-weight: 500;
    margin-left: 30%;
  }

  .csramount {
    font-size: 0.95rem;
    font-weight: 500;
    margin-left: 24%;
  }

  .solaramount {
    font-size: 0.95rem;
    font-weight: 500;
    margin-left: 37%;
  }

  .button-footer-2 {
    position: absolute;
    left: 50%;
    margin-top: 5rem;
    transform: translateX(-50%);

  }

  .button-font-size {
    margin-top: 12px;
    color: #EE3E80;
    font-size: 0.9rem;
  }

  .button-footer-4 {
    height: 3rem;
    width: 22rem;
    border-radius: 15px;
    background: transparent;
    border: 1px solid #FEF0E4;
  }


  .heading1 {
    font-size: 0.95rem;
    font-weight: 500;
  }

  .heading2 {
    font-size: 0.75rem;
    font-weight: 400;
    margin-right: 35%;
  }

  .kfs-amount {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .check-box {
    accent-color: #ee3e80;
    border-radius: 16px;
    border-color: #ee3e80;
    height: 18px;
    width: 18px;
  }


  .check-box2 {
    accent-color: #ee3e80;
    border: none !important;
    background-color: transparent;
    height: 12px;
    width: 12px;
    margin-left: 21%;
  }



  .text-down1 {
    color: #45C1C0;
    font-size: 0.75rem;
    margin-left: 8%;
    margin-top: -4%;
  }

  .text-down2 {
    color: #9F9D9D;
    font-size: 0.75rem;
    margin-top: -1%;
  }

  .text-down3 {
    font-size: 0.75rem;
    color: #3E3E3E;
    margin-left: 8%;
    text-align: justify;

  }

  .text-down4 {
    font-size: 0.75rem;
    color: #45C1C0;
  }

  .break-line {
    width: 22.5rem;
    height: 0.0375rem;
    background: #FEF0E4;
  }

  .credited-amount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.3rem;
    padding: 0.6rem;

    .credit-text {
      color: var(--text-primary, #3E3E3E);
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.0075rem;
    }

    .credit-value {
      color: var(--text-primary, #3E3E3E);
      text-align: right;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.00875rem;
    }
  }

  .credit-card-report {
    width: 20.5rem;
    height: 3rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid #E9ECEF;
    background: #FFF;
    padding: 0.6rem;
    margin-top: 1.38rem;
    display: flex;
    justify-content: space-between;

    .text-1 {
      .text-2 {
        color: var(--text-primary, #3E3E3E);
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.0075rem;
      }

      .msg {
        color: var(--text-secondary, #9F9D9D);
        font-size: 0.625rem;
        font-weight: 400;
        letter-spacing: 0.00625rem;
      }
    }
  }

  .terms-and-condition {
    color: var(--text-secondary, #9F9D9D);
    font-size: 0.625rem;
    font-weight: 400;
    letter-spacing: 0.00625rem;
    margin-top: 1.88rem;

    span {
      color: #EE3E80;
    }
  }
}

.blur-background {
  filter: blur(5px); 
  pointer-events: none; 
}


.bpbContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25.28rem;
  background: white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.0784313725);
  border-radius: 10px;
  min-width: 97vw;
  max-width: 500px;
  


  .insideContainer {
    padding: 1rem;
  } 

  .heading3 {
    font-size: 0.95rem;
    font-weight: 500;
  }


  .heading4 {
    font-size: 0.85rem;
    font-weight: 500;
    margin-top: 5%;
  }

  .heading5 {
    font-size: 0.85rem;
    font-weight: 500;
    margin-top: 5%;
    margin-left: 50%;
  }

  .heading7 {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .text-down4 {
    font-size: 0.75rem;
    color: #45C1C0;
    margin-top: 2%;
  } 

  .tc6 {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .tc7 {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5%;
    margin-top: 5%;
  }
  .text-down5 {
    color: #9F9D9D;
    font-size: 0.75rem;
    margin-left: 10%;
  }

  .text-down6 {
    color: #9F9D9D;
    font-size: 0.75rem;
  }

  .text-down7 {
    font-size: 0.9rem;
    color: #EE3E80;
    position: absolute;
    bottom: 2rem;
    width:82%;
    left: 54%;
    transform: translateX(-50%);
  } 

  .container-button{
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.dematContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16.28rem;
  background: white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.0784313725);
  border-radius: 10px;
  min-width: 97vw;
  max-width: 500px;
  border: 1px solid #EE3E80;

  .tc8 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .heading8 {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .text-down9 {
    color: #9F9D9D;
    font-size: 0.75rem;
  }

  .tc9 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .text-down10 {
    font-size: 0.85rem;
  }
}

.baContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20.28rem;
  background: white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.0784313725);
  border-radius: 10px;
  min-width: 97vw;
  max-width: 500px;
  border: 1px solid #EE3E80;

  .tc9 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .heading9 {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .text-down10 {
    color: #9F9D9D;
    font-size: 0.75rem;
  }

  .tc10 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .text-down11 {
    font-size: 0.85rem;
  }
}

.paymentContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20.28rem;
  background: white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.0784313725);
  border-radius: 10px;
  min-width: 97vw;
  max-width: 500px;
  border: 1px solid #EE3E80;


  .insideContainer {
    padding: 1rem;
  } 

  .tc9 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
  }

  .heading9 {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .text-down10 {
    color: #9F9D9D;
    font-size: 0.75rem;
  }

  .tc10 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .text-down11 {
    font-size: 0.85rem;
  }

  .text-down12 {
    color: #9F9D9D;
    font-size: 0.75rem;
    margin-left: 10%;
    
  }
}

.dematContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16.28rem;
  background: white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.0784313725);
  border-radius: 10px;
  min-width: 97vw;
  max-width: 500px;
  border: 1px solid #EE3E80;

  .tc8 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .heading8 {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .text-down9 {
    color: #9F9D9D;
    font-size: 0.75rem;
  }

  .tc9 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .text-down10 {
    font-size: 0.85rem;
  }
}

.insuranceContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 22.28rem;
  background: white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.0784313725);
  border-radius: 10px;
  min-width: 97vw;
  max-width: 500px;
  border: 1px solid #EE3E80;

  .tc9 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .heading9 {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .text-down10 {
    color: #9F9D9D;
    font-size: 0.75rem;
  }

  .tc10 {
    display: flex;
    grid-template-columns: auto 1fr;
    gap: 4%;
    padding: 1rem;
  }

  .text-down11 {
    font-size: 0.85rem;
  }
}

.close-button{
  position: absolute;
  left: 50%;
  margin-top: 5rem;
  transform: translateX(-50%);
  background: transparent;
  color: #ee3e80;
  border-color: white;
  bottom: 1px;
  border-radius: 10px;
  min-width: 96vw;
  max-width: 500px;
  height: 2rem;
  box-shadow: 0px;
}

@keyframes moveBottomPopUpLoanDetails {
  from {
    top: 15rem;
  }

  to {
    top: 9rem
  }
}

@media (min-width: 500px) {
  .loan-details-page {
    width: 22.5rem;
  }

  .tncAgreement {
    width: 19rem;
  }
}

@media (max-width: 500px) {
  .kfs-details {
    width: 100% !important;
  }
}

.question-svg {
  margin-left: 0.2rem;
}

/* For Webkit browsers (Chrome/Safari) */
.loan-details-page::-webkit-scrollbar {
  display: none;
}

.kfs-details::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.loan-details-page,
.kfs-details,
.tncAgreement {
  scrollbar-width: none;
}

/* For IE and Edge */
.loan-details-page,
.kfs-details,
.tncAgreement {
  -ms-overflow-style: none;
}

.tncAgreement::-webkit-scrollbar {
  display: none;
}

.tncAgreement::-webkit-scrollbar {
  display: none;
}

.loan-details-button-kfs {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
}

.know-more {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end;

  .know-more-tag {
    color: #EE3E80 !important;
    font-size: 0.625rem;
    font-weight: 400;
    letter-spacing: 0.00625rem;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.know-more-content {
  color: var(--text-primary, #3E3E3E);
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 0.9375rem;
  /* 150% */
  letter-spacing: 0.00625rem;
}

#my-tooltip {
  display: inline-flex !important;
  padding: 0.5rem 2.25rem 0.5rem 0.375rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.4px solid #E9ECEF;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;
  position: absolute;

}

.tncAgreement {
  position: absolute;
  height: 500px;
  z-index: 10;
  padding: 20px;
  background: #FFFF;
  border-radius: 20px;
  overflow-y: scroll;
  overflow-x: scroll;
  margin: 2rem;
}