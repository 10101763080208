.partner-input-box-container {
  display: flex;
  flex-direction: row;

  input, span {
    border-radius: 0;
    box-shadow: none;
    height: 2.6rem;
    // width: 1.4rem;
  }

  input {
    padding: 0;
    text-align: center;
    // width: auto;
  }

  input:focus {
    // border: none;
    box-shadow: none;
    border: 1px solid #F69EBF;
  }

  span {
    width: 2.1rem;
    padding: 0;
  }

}

.partner-heading {
  color: var(--Label-Primary, #707070);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.00875rem;

  span {
    color: red;
  }
}

::placeholder {
  color: var(--Text-Secondary, #B0B0B0);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  /* H3/16PX/400 */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01rem;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: var(--Text-Secondary, #B0B0B0);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  /* H3/16PX/400 */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01rem;
}

.partner-supportive-text-red {
  color: var(--Text-T-Error, #DC3545);
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
}

.partner-supportive-text-green {
  color: var(--Text-T-Error, green);
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
}

.action-button-style {
  margin-left: 4px;
  color: green;
  display: flex;
  align-items: center;
}