.blurrimages{
    position: absolute;
  }
  
  .blurrdiv, .blurrimages{
    // overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
  }
  
  @media (min-width: 500px) {
    .blurr-div, .blurr-images{
      // overflow: hidden;
      width: 22.5rem;
      height: 100%;
      top: 0;
    }
  }
  
  .blurrimages{
  
    .bluecircle{
      animation: moveRight 2s forwards;
    }
  
    .purplecircle{
      animation: moveToBottom 2s forwards;
    }
  
    .pinkcircle{
      animation: moveToTop 2s forwards;
    }
  
    .zigzag{
      animation: moveToBottom2 2s forwards;
    }
  
    @keyframes moveRight {
      to {
        left: -100px;
        top: 60px;
      }
    }
  
    @keyframes moveToBottom {
      0% {
        transform: translateY(0) translateX(0);
      }
      100% {
        transform: translateY(70%) translateX(-50px);
      }
    }
  
    @keyframes moveToBottom2 {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(70%);
      }
    }
  
    @keyframes moveToTop {
      from {
        top: 30%;
        left: 40%;
      }
      to {
        top: 2%;
        left: 75%;
      }
    }
  
  }

  .containers {
    display: flex;
}

.back, .arthlogo {
    flex: 1;
}

  
  .blurrdiv{
    flex-shrink: 0;
    background: lightgray 0% 0% / 154.22531366348267px 154.22531366348267px repeat, radial-gradient(189.03% 157.83% at -4.7% -2.49%, rgba(232, 232, 232, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(10px);
    position: absolute;
  }
  
  .purplecircle{
    display: inline-block;
    width: 288px;
    height: 288px;
    flex-shrink: 0;
    border-radius: 288px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(71, 49, 146, 0.73) 0%, rgba(255, 255, 255, 0.16) 100%);
    position: absolute;
    bottom: 100px;
    left: -110px;
  }
  
  .bluecircle{
    width: 10.75rem;
    height: 10.75rem;
    flex-shrink: 0;
    position: absolute;
    top: -80px;
    left: 80px;
  }
  
  .pinkcircle{
    width: 4.875rem;
    height: 4.875rem;
    flex-shrink: 0;
    position: absolute;
    top: 30%;
    left: 40%;
  }
  
  .zigzag{
    position: absolute;
    top: 100px;
    right: 0px;
  }
  
  .arthlogo-mobile-otp{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 2.38rem;
  
    .arthlogo-otp{
      margin-bottom: 10px;
    }
  
    .mobile-otp-svg{
      left: 6.9rem;
      width: 8.72006rem;
      height: 8.72006rem;
      flex-shrink: 0;
    }
  }
  
  .arthlogo-circle{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 14.21rem;
    animation: moveLogoDiv 2s forwards;
    animation-delay: 0.5s;
  
    .logo-coin{
      left: 6.9rem;
      width: 8.72006rem;
      height: 8.72006rem;
      flex-shrink: 0;
      animation: resizeLogo 2s forwards;
      animation-delay: 0.5s;
    }
  
    .arth-msg{
      white-space: nowrap;
      color: #EE3E80;
      font-size: 1.04644rem;
      font-weight: 400;
      letter-spacing: 0.01044rem;
      animation: resizeMsg 2s forwards;
      animation-delay: 0.5s;
      margin-top: 0.75rem;
    }
  
    @keyframes resizeLogo {
      from  {
          width: 8.72006rem;
          height: 8.72006rem;
          flex-shrink: 0;
      }
      to {
          width: 6.25rem;
          height: 6.25rem;
          flex-shrink: 0;
      }
    }
  
    @keyframes moveLogoDiv {
      from {
          top: 14.21rem;
      }
      to {
          top: 4.5rem;
      }
    }
  
    @keyframes resizeMsg {
      from {
        font-size: 1.04644rem;
      }
      to {
        font-size: 0.75rem;
      }
    }
  
  }
  
  .claim-offer-msg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .arth-logo-otp{
      margin-top: 2.12rem;
    }
  
    .congratulations{
      color: var(--text-primary, #3E3E3E);
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.01rem;
      margin-top: 0.67rem;
    }
    
    .offer-amount-msg{
      color: var(--text-secondary, #9F9D9D);
      text-align: center;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.0075rem;
      margin-top: 0.5rem;
    }
  }
  
  .arthlogo{
    display: flex;
    flex-direction: row;
    margin: 1.4rem 5rem 1.05rem 1rem;
  }
  
  .back{
    display: flex;
    flex-direction: row;
    margin: 2.12rem 1rem 1.05rem 1rem;
  }

  .heading{
    margin:0 1rem;
    display: flex;
    justify-content: space-between;
  
    span{
      cursor: pointer;
    }
  }
  
  .custom-progress-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 1rem;
  
    .progress-top {
      display: flex;
      width: 17.8125rem;
      height: 0.5rem;
      padding-right: 0px;
      align-items: center;
      border-radius: 0.25rem;
      background: #E9ECEF;
  
      .progress-done {
        border-radius: 0.50169rem;
        background: linear-gradient(135deg, #F9A961 2.4%, #FED3AC 97.92%);
        height: 100%;
        width: 0;
        opacity: 0;
        transition: 1s ease 0.3s;
      }
    }
  }
  
  .copyright{
    position: absolute;
    margin-top: 20rem;
    color: var(--Text-Primary, #3E3E3E);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.0075rem;
  }