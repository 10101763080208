.card-container1 {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px; 
}


.card-body {
  padding: 20px;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 45%; 
}
.right{
  width: 10%;
}