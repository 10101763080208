.riskProfile-container {
    display: "flex";
    border-radius: 10px;
    border: solid;
    border-width: 1px;
    border-color: #B9B9B9;
    margin-top: 50;
    // display: flex;
    padding-inline: 14px;
    padding-block: 10px;
    //padding: "10px"
  }
  .heading-risk-chart {
    //styleName: H5/12PX/500;
font-family: 'Montserrat';
font-size: 12px;
font-weight: 500;
line-height: 14.63px;
letter-spacing: 0.01em;
text-align: center;
color: #707070;
  }
  .subheading-risk-chart {
    //styleName: H5/12PX/500;
font-family: 'Montserrat';
font-size: 14px;
font-weight: 600;
line-height: 14.63px;
letter-spacing: '0.01em';
text-align: start;
color: #707070;
  }
  .heading-risk-chart {
    //styleName: H5/12PX/500;
font-family: 'Montserrat';
font-size: 12px;
font-weight: 500;
line-height: 14.63px;
letter-spacing: 0.01em;
text-align: center;
color: #707070;

  }
  .subheading-risk-chart {
    //styleName: H5/12PX/500;
font-family: 'Montserrat';
font-size: 14px;
font-weight: 600;
line-height: 14.63px;
letter-spacing: '0.01em';
text-align: start;
color: #707070;

  }
  
//   .pan-name {
//     color: #10b759;
//     margin-top: 10px !important;
//   }
  
//   .carousel {
//     overflow: hidden;
//     min-width: 100%;
//     width: 0;
//   //   min-height: 100%;
//   justify-content: center;
//   align-items: center;
//   }
  
//   .kyc-font {
//     color: #707070;
//     font-family: Montserrat;
//     font-weight: 600;
//     font-size: 14px;
//   }
  
//   .kyc-subheading{
//     font-weight: 500;
//   }
  
//   .kyc-key {
//     font-weight: 400;
//   }
  
//   .kyc-value {
//     color: #3E3E3E;
//     font-weight: 500;
//     font-size: 12px
//   }
  
//   .kyc-content{
//     // margin-left: 20px;
//     margin-top: 10px;
//   }
  
//   .kyc-margin{ 
//     margin-top: 10px;
//   }
  /* App.css */
// .first {
//     width: 66%;
//   }
  
  .mainDiv {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
// //   .heading {
// //     font-size: 24px;
// //     margin-bottom: 20px;
    
// //   }
  
  .details-riskProfile {
    display: flex !important;
  }
  
  .headingChart {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .chartoccuptaion {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: "0.4rem";
    height: 138px;
    margin-top: "0.4rem";
    height: 138px;
    // width: 226px;
    // height: 300px; /* Adjust as needed */
  }