.repayment-detail-1 {
    height: 10rem;
    background: #fff;
    position: relative;
    border-radius: 12px;
    border: 1px solid #FEF0E4;
    min-width: 91vw;
}

.repayment-details-page{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height:calc(100% - 6rem);
    bottom: 0;
    border-radius: 1rem 1rem 0rem 0rem;
    background: #FFF;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 10.5rem;
    overflow: scroll;
    animation: moveBottomPopUpLoanDetails 2s forwards;
    padding: 2.125rem 1rem 11.6875rem 1rem;
}

.repayment {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.check-box {
  accent-color: #ee3e80;
  border-radius: 16px;
  border-color: #ee3e80;
  height: 18px;
  width: 18px;
}


.repayment-detail-2 {
    height: 11rem;
    background: #fff;
    position: relative;
    border-radius: 12px;
    border: 1px solid #FEF0E4;
    min-width: 91vw;
}

.breakLine{
    width: 23.6rem;
    height: 0.0375rem;
    background: #FEF0E4;
}

.description-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .description-table th,
  .description-table td {
    padding: 8px;
  }
  
  .description-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .description-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ecf1fd;
    color: #9f9d9d9d;
    border: 0;
    color: var(--text-primary, #3E3E3E);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.9375rem;
    letter-spacing: 0.00625rem;
}