.pagination-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 24px;
}


.popover-arrow {
  display: none !important;
}

.arrow-buttons {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 5px;
  color: #b0b0b0;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.pagination-buttons {
  display: block ruby;
  border-radius: 10px;
  align-items: center;
  background-color: white;
}

.paginator-divider {
  border-left: 1px solid black;
  height: 25px;
  border-color: #d5d5d5;
}

// .MuiPaginationItem-page{
//   display: none !important;
// }

.css-1v2lvtn-MuiPaginationItem-root {
  display: none !important;
}