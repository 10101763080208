.hunting-form-button{
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.hunting-form {
  padding-top: 60px;
}

.sticky-style {
  position: fixed;
  top: 0;
  background-color: #F9FAFB;
  z-index: 1;
  width: 100%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #EE3E80;
}