.basic-left-body {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    width: 140px !important;
}

.big-basic-left-body{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    width: 180px !important;
    color: #3E3E3E
}

.basic-right-body {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    color: #3E3E3E;
}

.basic-details-container {
    background-color: white;
    border-radius: 10px;
    padding-block: 1rem;
    padding-inline: 10px;
    box-shadow: 6px 6px 54px 0px #00000008;
}

.basic-details-body {
    padding-top: 10px;
}

.big-box{
    width: 100%;
    height: 100%;

}

.big-box-mrgn {
    margin-left: 300px;
}

.match-tick {
    margin-left: 5px;
    color: #10B759 !important;
}

.unmatch-tick {
    margin-left: 5px;
    color: #DC3545
}

.seperator {
    margin-top: 10px;
    border: solid;
    border-width: .6px;
    color: #B9B9B9;
}

.demographics-container {
    background-color: white;
    border-radius: 10px;
    padding-block: 1rem;
    border: solid;
    border-width: 0.5px;
    border-color: #B9B9B9;
    padding-inline: 10px;
}

