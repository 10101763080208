@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");

body{
    color: var(--text-primary, #3E3E3E) !important;
    font-variant-numeric: lining-nums proportional-nums !important;
    font-feature-settings: 'dlig' on !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    line-height: normal !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: inherit !important;
    font-weight: inherit !important;
}

.main{
    display: flex;
    /* background-color: black; */
    /* justify-content: center; */
    width: 100vw;
    height: 100svh;
    /* overflow: hidden; */
    
    .main-container {
        background-color: #FFFFFF;
        /* width: 22.5rem; */
        /* display: flex; */
        /* justify-content: center; */
        width: 100%;
        height: 100vh;
    }
}

input::placeholder{
    color: var(--text-secondary, #9F9D9D);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;

    /* Text/Medium 14PX */
    font-family: Montserrat !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
}

textarea::placeholder {
    color: var(--text-secondary, #9F9D9D);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;

    /* Text/Medium 14PX */
    font-family: Montserrat !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
}

input{
    color: var(--text-primary, #3E3E3E);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;

    /* Text/Medium 14PX */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
}

@media (min-width: 500px) {
    .main{
        display: flex;
        background-color: black;
        justify-content: center;
        width: 100%;
        height: 100svh;
        /* overflow: hidden; */
        position: absolute;
        
        .main-container {
            background-color: #FFFFFF;
            width: 22.5rem;
            height: 100vh;
            /* overflow: hidden; */
        }
    }
}

