
.bureau-detail-container {
    background-color: #EDF9F9;
    padding-inline: 10px;
    padding-bottom: 10px;
}

.bureau-container {
    background-color: white;
    border-radius: 10px;
    padding-top: 1rem;
    justify-content: center;
}

.good-score {
    color: #10B759;
}

.bad-score {
    color: #DC3545
}

.average-score {
    color: #F1B300
}

.history-col-txt {
    color: #B0B0B0;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
}

.history-header-txt {
    color: #404040;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px
}

.history-value-one {
    color: #707070;
    font-size: 12px;
    font-weight: 500;
    font-family: Montserrat;
}

.history-value-two {
    color: #3E3E3E;
    font-size: 14px;
    font-weight: 500;
    margin-left: auto;
}