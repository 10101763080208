

.filter-tab{
    cursor: pointer;
}

.text-style {
    font-size: 12px;
    font-family: Montserrat;
    color: #707070;
    font-weight: 500px;
}

.active {
    color: black;
}

.underline {
    padding-top: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 5px;
    background-image: linear-gradient(#EE3E80,#FF75A9);
    width: 75%;
    margin-top: 5px;
    margin-right: 10px;
}

.noti-circle {
    margin-left: 12px;
    background-color: #EE3E80;
    background-image: linear-gradient("#EE3E80", "#FF75A9") !important;
  }

.grid-layout {
    display: grid;
    grid-template-columns: 1fr auto;
}

.circle-text{
    margin-left: 2px;
    margin-right: 1px;
    margin-top: 1px;
}