.middle-container {
    // height: 250px;
    padding-top: 25px
}


.sub-outer-box{
    border-radius: 10px;
    border: solid;
    border-width: 1px;
    border-color: #B9B9B9;
    background-color: white;
    width: -webkit-fill-available;
}

.common-top-margin {
    margin-top: 10px
}

.common-border-properties {

    border-radius: 10px
}

.pink-txt-btns {
    cursor: pointer;

	background: linear-gradient(to right, #EE3E80 0%, #FF75A9 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font: {
        size: 12px;
        font-weight: 500;
		family: "Montserrat";
	};

}

.hdr-style{
    color: #707070;
    font-weight: 600;
    font-size: 14px;
    font-family: "Montserrat";
}

.back-comps {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
}


