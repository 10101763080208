.filters-data {
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    color: #47474F;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;
  
    .all, .in-progress, .re-kyc-request, .qc-pending, .Approved, .Complete{
      border-right: 0.6px solid #D0D5DD;
      padding: 0.6rem;
      background-color: #FFF;
      cursor: pointer;
      text-decoration: none;
    }
  
    .active-filter {
      background-color: #EE3E80;
      color: white;
      border: 1px solid #D0D5DD;
      border-radius: 10px;
      font-size: 0.875rem;
    font-weight: 500;
    }
    
  }
  
  .search-input-type {
    .partner-dropdown {
      select{
        border: 1px solid #D0D5DD;
      }
    }
  }
  .apply-button {
    background-color: #EE3E80;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  .search-input:focus {
    border: 1px solid #D0D5DD;
    box-shadow: none;
  }
  .form-control-width {
    width: auto;
  }
  @media (max-width: 550px) {
    .dataInputlabel {
      margin-top: 2%;
      // height: 300px;
    }
    .form-control-width {
      width: 100%;
    }
    .marginTopSpace {
      margin-top:3%;
    }
    .margin-bottom-list {
      margin-bottom: 0.5rem;
    }
  }
  .form-active-input-search {
    //padding: 0.175rem 0.25rem .175rem .45rem;
  }