.container2 {
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 2rem 1.5rem;
}

.container3 {
  background-color: #fff;
  padding: 0 1rem 1rem;
}

.container4 {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.container5 {
  //width: 97%;
  //margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
}

.flex-item-left {
  flex-grow: 1;
  span {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.flex-item-right {
  a {
    border: 1px solid #000;
    border-radius: 2px;
    padding: 6px 10px;
  }
}

.partner-header {
  background-color: rgb(236, 241, 253);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  .partner-name {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.customer-invoice-heading {
  padding-bottom: 5px;
  font-weight: bold;
}

.partner-business-name, .partner-address, .customer-header, .customer-name, .customer-invoice-details, .customer-details {
  display: flex;
  flex-direction: column;
}

.customer-invoice-details {
  line-height: 1.5rem;
}

.customer-header {
  padding: 1rem;
}

.customer-details {
  flex-direction: row;
  align-items: center;
}

.customer-name {
  flex-grow: 1;
  line-height: 1.5rem;
}

.invoice-footer {
  padding-top: 1rem;
  display: flex;
}

.invoice-footer-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  line-height: 1.5rem;
  .left-item {
    flex-grow: 1;
  }
}

.description-table {
  border-collapse: collapse;
  width: 100%;
}

.description-table th,
.description-table td {
  padding: 8px;
}

//.description-table tr:nth-child(even) {
//  background-color: #f2f2f2;
//}

.description-table th {
  //padding-top: 12px;
  //padding-bottom: 12px;
  text-align: left;
  background-color: #ecf1fd;
  color: #000000;
  border: 0;
  font-weight: 100;
}

.description-table button {
  background-color: #f44336;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

.invoice-logo {
  //float: left;
  img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}

.invoice-thankyou {
  padding: 2rem 0;
  font-size: 1rem;
}

/* Responsive styles */
@media only screen and (max-width: 600px) {
  .partner-header, .customer-header {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .container5 {
    padding-bottom: 0.5rem;
  }
  .container2 {
    padding: 1rem;
  }
  .flex-item-right a {
    padding: 4px 8px;
  }
  .flex-item-left {
    span {
      font-size: 1rem;
    }
  }
  .description-table th, .description-table td {
    font-size: 0.9rem;
    padding: 5px;
  }
  .container3 {
    padding: 0 0.5rem 0.5rem;
  }
  .invoice-footer-items, .invoice-thankyou {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 450px) {
  .partner-header, .customer-header {
    padding: 0.4rem;
    font-size: 0.7rem;
  }
  .container5 {
    padding-bottom: 0.5rem;
  }
  .container2 {
    padding: 0.6rem;
  }
  .flex-item-right a {
    padding: 2px 6px;
  }
  .flex-item-left {
    span {
      font-size: 0.9rem;
    }
  }
  .description-table th, .description-table td {
    font-size: 0.7rem;
    padding: 3px;
  }
  .container3 {
    padding: 0 0.3rem 0.3rem;
  }
  .invoice-footer-items, .invoice-thankyou {
    font-size: 0.7rem;
    line-height: 1rem;
  }
  .invoice-logo {
    img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  }
  .customer-name, .customer-invoice-details {
    line-height: 1rem;
    font-size: 0.7rem;
  }
}