$tableheadcolor: #F5F5F5;

.thead {
  background-color: $tableheadcolor;
  height: 49px;
  font-size: 14px;
}

.head-font {
  font-size: "14px";
  font-family: "Montserrat";
  font-weight: 600;
  color: "#707070";
  margin-left: "10px"
}

.data-table {
    margin-top: 10px;
    display: grid;
    background-color: #FFFFFF;
   
    border: solid;
    border-radius: 10px;
    border-width: 1px;
    border-color: #B9B9B9
  }

  .tbody {
    height: 40px
  }

.table-row {
    font-size: 12px;
    font-weight: 500;
}

.table-row:hover {
  background: #d5d5d5;
  color: black;
  cursor: pointer;
}


.text-font {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: "12px";
  color: "#3E3E3E"
}
