.kyc-container {
  display: "flex";
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  border-color: #B9B9B9;
  margin-top: 50;
  padding-inline: 14px;
  padding-block: 10px;
}

.pan-name {
  color: #10b759;
  margin-top: 10px !important;
}

.carousel {
  overflow: hidden;
  min-width: 100%;
  width: 0;
//   min-height: 100%;
justify-content: center;
align-items: center;
}

.kyc-font {
  color: #707070;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 5px;
}

.kyc-subheading{
  font-weight: 500;
}

.kyc-key {
  font-weight: 400;
}

.kyc-value {
  color: #3E3E3E;
  font-weight: 500;
  font-size: 12px
}

.kyc-content{
  // margin-left: 20px;
  margin-top: 20px;
}

.kyc-margin{ 
  margin-top: 10px;
}