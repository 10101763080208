.box-format {
    padding-block: 5px;
    padding-inline: 10px;
    border: solid;
    border-width: 1px;
    border-radius: 10px;
    border-color: white;
    background-color: white;
    padding: 10px;
}

.box-top-text{
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    color: #3E3E3E;
}

.box-bottom-text {
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    color: #707070;
}

.risk-score-container {

    background-color: white;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    padding: 10px;

}

.risk-content-container {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.scale-style {
    width: 150px;
    height: 50px
}

.pointer-style {
    position: absolute;
    border-left: solid;
    border-width: 1px;
    height: 110px;
    margin-bottom: 100px;
}


.reject-hover:hover {
    background-color: #FBEBEC !important;
}

.approve-hover:hover {
    background-color: #E7F8EE !important;
}

.hold-hover:hover { 
    background-color: #FEF7E6 !important;
}