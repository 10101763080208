.hdng-container {
    display: flex;
    border: solid;
    border-width: 1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-color: #B9B9B9;
    background-color:#F5F5F5 ;
    margin-top: 5px;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 12px;
    color: #707070;
    padding-block: 5px;
}

.first-col-style {
    margin-left: 40px;
}

.trns-cntnt {
    display: flex;
    font-family: Montserrat;
    font-weight: 500px;
    color: #3E3E3E;
    font-size: 12px;
    margin-top: 5px;
}