.loader-color {
  width: 1.16669rem !important;
  height: 1.16669rem !important;
  border: 3px solid transparent;
  border-bottom: 3px solid #FED3AC !important;
  border-top: 3px solid #F9A961 !important;
  border-right-color: rgba(255, 255, 255, 0.7) !important;
  border: 3px solid transparent ;
}

.model-background-2{
  display: flex;
  width: 100%;
  height: 100%;
  padding: 7.6875rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.50);
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  .lottie-animaton {
    width: 250px;
  }
}