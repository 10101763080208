.top-sell-item{
    display: flex;
    height: 2.625rem;
    max-width: 170px;
    margin-right: 5px;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid var(--field-Inactive, #E9ECEF);
    background: var(--field-Active, #FFF);
    box-shadow: 1px 3px 6px 0px rgba(143, 143, 143, 0.08);
    cursor: pointer;
  }
  .card-retailers-title {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .complete-sucessfully-img {
    width: 108px;
height: 108px;
top: 30px;
left: 160px;
gap: 0px;

  }
  .apply-button {
    background-color: #EE3E80;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  .sell-iem-count {
    display: flex;
    // width: 7.0625rem;
    height: 2.625rem;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid var(--field-Inactive, #E9ECEF);
    background: var(--field-Active, #FFF);
    box-shadow: 1px 3px 6px 0px rgba(143, 143, 143, 0.08);
    cursor: pointer;
  }
  
  .partner-card-logo-permission {
    color: var(--Label-Primary, #707070);
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.0075rem;
  }
  
  .sell-item-error {
    color: var(--Text-T-Error, #DC3545);
    font-size: 0.625rem;
    font-weight: 400;
    letter-spacing: 0.00625rem;
  }
  
  #my-tooltip{
    display: inline-flex !important;
    padding: 0.5rem 2.25rem 0.5rem 0.375rem;
    align-items: center;
    border-radius: 0.5rem;
    border: 0.4px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 2;
    position: absolute;
    color: #DC3545;
  }
  
  .cursor-pointer {
    cursor: pointer;
    color: #EE3E80;
  }
  .retailer-funnel{

    .card{
  
      .funnel-body {
        //padding: 2.5rem 1.75rem;
  
        .partner-heading {
          font-size: 1rem;
          line-height: 2;
        }
  
        .top-bar-funnel {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;
        }
  
        .colored-funnel {
          border-radius: 0.25rem;
          border: 1px solid #E9ECEF;
          background: #FFF;
          padding: 1rem;
  
          @media screen and (max-width: 600px) {
            padding: 0.5rem;
          }
  
          .funnel {
            display: flex;
            justify-content: center;
            align-items: center;
  
            .trapezoid-inner {
              position: absolute;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 2rem;
            }
  
            .trapezoid {
              border-bottom: 6px solid transparent;
              border-top: 6px solid transparent;
              border-left: 11vw solid;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
  
            .trapezoid-1 {
              border-color: #F84540;
              height: 18.315rem;
              border-radius: 5px;
            }
  
            .trapezoid-2 {
              border-color: #FA642D;
              height: 17.6rem;
            }
  
            .trapezoid-3 {
              border-color: #FD8C15;
              height: 16.95rem;
            }
  
            .trapezoid-4 {
              border-color: #D19E0F;
              height: 16.25rem;
            }
  
            .trapezoid-5 {
              border-color: #A5A112;
              height: 15.55rem;
            }
  
            .trapezoid-6 {
              border-color: #07AC1C;
              height: 14.85rem;
            }
  
            @media screen and (max-width: 600px) {
              flex-direction: column;
  
              .rect-data {
                width: 100%;
                flex-direction: row;
              }
  
              .trapezoid-inner {
                flex-direction: row;
                span {
                  width: 6rem;
                  min-height: auto;
                }
                .white-circle {
                  width: 2.5rem;
                  height: 2.5rem;
                }
              }
  
              .trapezoid {
                border-bottom: none;
                border-top: 15vw solid;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                width: 100%;
                border-top-right-radius: 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
              }
  
              .trapezoid-1 {
                border-color: #F84540;
                height: auto;
                width: 60vw;
                border-top-right-radius: 5px;
              }
  
              .trapezoid-2 {
                border-color: #FA642D;
                height: auto;
                width: 57vw;
              }
  
              .trapezoid-3 {
                border-color: #FD8C15;
                height: auto;
                width: 54.5vw;
              }
  
              .trapezoid-4 {
                border-color: #D19E0F;
                height: auto;
                width: 52vw;
              }
  
              .trapezoid-5 {
                border-color: #A5A112;
                height: auto;
                width: 50vw;
              }
  
              .trapezoid-6 {
                border-color: #07AC1C;
                height: auto;
                width: 48vw;
              }
            }
          }
        }
  
        @media screen and (max-width: 600px) {
          padding: 0.5rem;
  
          .top-bar-funnel {
            gap: 0.5rem;
          }
  
          .form-select {
            line-height: 1rem;
            font-size: 0.875rem;
            height: 100%;
          }
  
          .rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
            line-height: 1;
            font-size: 0.875rem;
            padding: .375rem .5rem .375rem .75rem;
          }
  
          rs-input-group .rs-form-control-wrapper~.rs-input-group-addon, .rs-input-group input.rs-input~.rs-input-group-addon {
            padding: 0.4rem;
          }
        }
  
      }
    }
  }
  
  .rect-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.00875rem;
    position: relative;
  
    span{
      max-width: 9rem;
      text-align: center;
      min-height: 2rem;
    }
  
    .white-circle {
      span {
        text-align: center;
        min-height: auto;
      }
      width: 4.375rem;
      height: 4.375rem;
      border-radius: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .hover-effect {
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .white-circle {
    transition: transform 0.3s ease;
  }
  
  .hover-effect:hover {
    transform: scale(1.1);
  }
  
  .hover-effect:hover .white-circle {
    transform: scale(1.1);
  }
  
  .np-funnel {
    display: flex;
    justify-content: space-between;
    border-radius: 0.25rem;
    border: 1px solid #E9ECEF;
    background: #FFF;
    padding: 1rem;
    color: var(--Label-Primary, #707070);
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;
  }
  
  .count-card {
    color: var(--Label-Primary, #707070);
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;
    display: flex;
    justify-content: space-around;
  
    div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }
  
    .count-card-click{
      transform: rotate(180deg);
    }
  }
  
  .trapezoid-1-color {
    color: #F84540;
  }
  .trapezoid-2-color {
    color: #FA642D;
  }
  .trapezoid-3-color {
    color: #FD8C15;
  }
  .trapezoid-4-color {
    color: #D19E0F;
  }
  .trapezoid-5-color {
    color: #A5A112;
  }
  .trapezoid-6-color {
    color: #07AC1C;
  }