.action-buttons {
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  font-family: Montserrat;
  background-color: white;
  gap: 2px;
}

.action-style {
  font-family: Montserrat;
  font-size: 14px;
  padding-inline: 20px;
  padding-block: 5px;
  background-color: #f5f6fa;
  border-color: #f5f6fa;
}

.action-style:hover {
  cursor: pointer;
}

.approve-style {
  color: #10b759;
  border-inline: solid;
  border-width: 0.5px;
  border-color: #f5f6fa;
}

.hold-style {
  color: #f1b300;
  border-right: solid;
  border-left: solid;
  border-width: 0.5px;
  border-color: #f5f6fa;
}

.hold-style:hover {
  background-color: #ffebbb;
}

.approve-style:hover {
  background-color: #e0ffcd;
}

.reject-style:hover {
  background-color: #ffcab0;
}

.reject-style {
  color: #dc3545;
  border-left: solid;
  border-width: 0.5px;
  border-color: #f5f6fa;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.left-most {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.action-hdr {
  display: flex;
  justify-content: center;
  align-items: center;
}