.switch-btn-business {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.sub-switch-container {
    border: solid;
    border-width: 0.5px;
    border-color: #B9B9B9;
    padding-inline: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}



.kyc-content-box {
    display: flex;
    padding-inline: 5px;
    padding-block: 10px;
    gap: 10px;
}

.common-row-spc {
    margin-top: 20px;
}
.custom-info-window {
    z-index: 1000 !important; /* Adjust z-index to ensure the InfoWindow is on top */
    /* Add other styles as needed */
}

/* Ensure the InfoWindow content container is styled properly */
.custom-info-window .gm-style-iw {
    z-index: 1000 !important; /* Adjust if needed */
    /* Add other styles for padding, background, etc. */
}