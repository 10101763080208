// .MuiSlider-thumb{
//     color: #F78D2D ;
// }

// .MuiSlider-rail {
//     color: #E9ECEF;
//     padding-block: 5px;
//     background-color: #E9ECEF;
//     border: 0px;
// }

.MuiSlider-track {
    width: none;
    color: #E9ECEF;
}

.MuiSlider-mark {
    color: #E9ECEF
}

.css-ttgsjq-MuiSlider-track{
    border: none !important;
    height: 0px !important;
}

.slider-sub-text {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    color: #B0B0B0;
}